import { Box, Grid } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { SEO } from '../../../../../atoms';
import { GenericPage, Layout } from '../../../../../layout';
import { ColorLabel } from '../../../../../molecules';
import useStyles from './IndividualListingPage.styles';
import {
  analyticCardConfig,
  analyticHistoryMockData,
  analyticScoreMockData,
  analyticTimelineConfig,
  conversionChartMockData,
  conversionFunnelChartMockData,
} from './utils';
import {
  AnalyticTimeline,
  AnalyticDetailShortCard,
  AnalyticDetailLongCard,
  AnalyticHistory,
  ConversionChart,
  AnalyticScoreTable,
  ConversionFunnelChart,
} from '../../../../../organisms';
import useAuthToken from '../../../../../../store/auth/hooks/useAuthToken';
import useIndividualListingAnalyticsData from '../../../../../../api/analytics/useIndividualListingAnalyticsData/useIndividualListingAnalyticsData';
import useMyUserDetails from '../../../../../../api/auth/me/useMyUserDetails';

export interface IndividualListingPageProps {
  developmentId: string;
}

export default function IndividualListingPage({
  developmentId,
}: RouteComponentProps<IndividualListingPageProps>) {
  const classes = useStyles();
  const authToken = useAuthToken();
  const { data: userDetailsData } = useMyUserDetails({
    authToken,
  });

  const { data: analyticsData } = useIndividualListingAnalyticsData({
    developmentId: Number(developmentId),
    orgId: userDetailsData?.organization.organizationId || 0,
    authToken,
    queryConfig: {
      enabled: userDetailsData?.organization.organizationId != null,
    },
  });

  return (
    <Layout>
      <SEO title="Individual listing" />
      <GenericPage>
        <Box className={classes.ctn}>
          <Box className={classes.contentCtn}>
            <Box className={classes.coverImage}>
              <img src="/images/cities/cover.jpg" alt="Cover Image" />
              <Box className={classes.titleCtn}>
                <ColorLabel className={classes.title} color="primary">
                  {analyticsData?.data.title}
                </ColorLabel>
                {analyticsData?.data.isPublic && (
                  <Box className={classes.tag}>Public</Box>
                )}
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={5}>
                  <Grid item xs={12} className={classes.analyticCard}>
                    <Grid container spacing={2}>
                      {analyticCardConfig(analyticsData?.data).map(
                        ({ type, title, ...args }) => (
                          <Grid
                            key={title}
                            item
                            xs={type === 'long' ? 12 : 6}
                            md={type === 'long' ? 4 : 3}
                          >
                            {title ? (
                              type === 'long' ? (
                                <AnalyticDetailLongCard
                                  key={title}
                                  title={title}
                                  {...args}
                                />
                              ) : (
                                <AnalyticDetailShortCard
                                  key={title}
                                  title={title}
                                  {...args}
                                />
                              )
                            ) : null}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <AnalyticTimeline data={analyticTimelineConfig} />
                  </Grid>
                  <Grid item xs={12}>
                    <AnalyticHistory data={analyticHistoryMockData} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box>
                      <ColorLabel className={classes.chartTitle}>
                        Conversions
                      </ColorLabel>
                      <Box className={classes.chartCtn} height={270}>
                        <ConversionChart data={conversionChartMockData} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <ColorLabel className={classes.chartTitle}>
                        Conversion funnel
                      </ColorLabel>
                      <Box className={classes.chartCtn} height={300}>
                        <ConversionFunnelChart
                          data={conversionFunnelChartMockData}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <AnalyticScoreTable
                      score={9}
                      data={analyticScoreMockData}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </GenericPage>
    </Layout>
  );
}
