import * as React from 'react';
import { Router } from '@reach/router';
import IndividualListingPage from '../../../../components/pages/my-account/analytics/property/IndividualListingPage';

export default function MyAccountAnalyticsPropertyUsersPage() {
  return (
    <Router basepath="/my-account/analytics/property">
      <IndividualListingPage path="/:developmentId" />
    </Router>
  );
}
