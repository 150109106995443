import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pvtFetcher from '../../utils/pvtFetcher';
import { IndividualListingAnalyticsData } from '../types';

export default function useIndividualListingAnalyticsData({
  developmentId,
  authToken,
  orgId,
  queryConfig,
}: {
  developmentId: number;
  authToken: string;
  orgId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = API_ENDPOINTS.GET_ANALYTICS_DEVELOPMENT_PRIVATE(developmentId);

  return useQuery<IndividualListingAnalyticsData, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { method: 'GET', authToken, orgId }),
    queryConfig
  );
}
