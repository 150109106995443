import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F8F8F8',
      alignItems: 'center',
      padding: theme.spacing(3, 2),
    },
    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: theme.custom.container1460,
      width: '100%',
      gap: theme.spacing(4),
    },
    coverImage: {
      overflow: 'hidden',
      display: 'flex',
      gap: theme.spacing(2),
      flexDirection: 'column',
      '& img': {
        borderRadius: theme.spacing(2),
        width: '100%',
        objectFit: 'cover',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        '& img': {
          maxWidth: 200,
        },
      },
    },
    analyticCard: {
      [theme.breakpoints.up('md')]: {
        marginTop: 44,
      },
    },
    analyticDetail: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing(2),
    },
    title: {
      fontWeight: theme.typography.fontWeightSemiBold,
      textTransform: 'uppercase',
    },
    titleCtn: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      gap: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    tag: {
      backgroundColor: '#99EAAC',
      padding: theme.spacing(0.25, 1),
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightSemiBold,
      borderRadius: theme.spacing(1),
    },
    chartTitle: {
      fontWeight: theme.typography.fontWeightSemiBold,
      textTransform: 'uppercase',
      color: theme.palette.gray.contrastText,
      marginBottom: theme.spacing(2.5),
    },
    chartCtn: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3, 1.5),
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    },
  })
);

export default useStyles;
