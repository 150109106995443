import * as React from 'react';
import ViewsIcon from '../../../../../../images/icons/Views';
import PhoneCallIcon from '../../../../../../images/icons/PhoneCall';
import EmailIcon from '../../../../../../images/icons/Email';
import AverageTimeIcon from '../../../../../../images/icons/AverageTime';
import { addDays } from 'date-fns';
import {
  AnalyticScoreData,
  AnalyticHistoryData,
  ConversionFunnelData,
} from '../../../../../organisms';
import { IndividualListingAnalyticsData } from '../../../../../../api/analytics/types';
import { formatDurationToString } from '../../../../../../utils/date';

const getAnalyticPercentage = (percentage?: number) =>
  percentage
    ? percentage === 1
      ? '100%'
      : `${Math.abs(100 - Math.round(percentage * 100))}%`
    : '--';

const getAnalyticPercentageAvgText = (percentage?: number) =>
  percentage
    ? percentage !== 1
      ? percentage > 1
        ? 'above average price'
        : 'below average price'
      : ''
    : '--';

export const analyticCardConfig = (
  data?: IndividualListingAnalyticsData['data']
) => [
  {
    title: 'Views',
    icon: <ViewsIcon />,
    content: data?.viewsCount ?? '--',
    type: 'long',
    selection: [
      { label: '24 hours', value: 1 },
      { label: 'A week', value: 2 },
      { label: 'A month', value: 3 },
    ],
  },
  {
    title: 'Calls generated',
    icon: <PhoneCallIcon />,
    content: data?.callCount ?? '--',
    type: 'long',
    selection: [
      { label: '24 hours', value: 1 },
      { label: 'A week', value: 2 },
      { label: 'A month', value: 3 },
    ],
  },
  {
    title: 'Email generated',
    icon: <EmailIcon />,
    content: data?.emailCount ?? '--',
    type: 'long',
    selection: [
      { label: '24 hours', value: 1 },
      { label: 'A week', value: 2 },
      { label: 'A month', value: 3 },
    ],
  },
  {
    title: 'Average time spent on page',
    icon: <AverageTimeIcon />,
    content: data?.averageEngagementTime
      ? formatDurationToString(Math.round(data.averageEngagementTime), {})
      : '--',
    type: 'long',
    selection: [
      { label: '24 hours', value: 1 },
      { label: 'A week', value: 2 },
      { label: 'A month', value: 3 },
    ],
  },
  {
    title: 'Location score',
    content: '--/--',
    description: '2 miles to the nearest station',
    type: 'long',
  },
  {
    type: 'long',
  },
  {
    title: 'Videos',
    content: '--',
    type: 'short',
    average: '--',
  },
  {
    title: 'Photos',
    content: '--',
    type: 'short',
    average: '--',
  },
  {
    title: 'Floor plans',
    content: '--',
    type: 'short',
    average: '--',
  },
  {
    title: 'Virtual tours',
    content: '--',
    type: 'short',
    average: '--',
  },
  {
    title: 'Studio',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.private_studio.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.private_studio.relativePriceLevel
    ),
  },
  {
    title: '1BR',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.private_1br.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.private_1br.relativePriceLevel
    ),
  },
  {
    title: '2BR',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.private_2br.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.private_2br.relativePriceLevel
    ),
  },
  {
    title: '3BR',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.private_3br.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.private_3br.relativePriceLevel
    ),
  },
  {
    title: '4BR',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.private_4br.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.private_4br.relativePriceLevel
    ),
  },
  {
    title: 'Private Room',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.shared.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.shared.relativePriceLevel
    ),
  },
  {
    title: 'Private ensuite',
    content: getAnalyticPercentage(
      data?.flatAveragePrice.shared_ensuite.relativePriceLevel
    ),
    description: getAnalyticPercentageAvgText(
      data?.flatAveragePrice.shared_ensuite.relativePriceLevel
    ),
  },
];

export const analyticTimelineConfig = [
  {
    label: 'Listing created',
    date: new Date(),
  },
  {
    label: '100 views',
    date: addDays(new Date(), 12),
  },
  {
    label: '1000 views',
    date: addDays(new Date(), 20),
  },
  {
    label: '100 leads',
  },
  {
    label: '1000 leads',
  },
];

export const analyticHistoryMockData: AnalyticHistoryData[] = [
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Visit',
    user: 'be99',
    userType: 'EE',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
  {
    status: 'Convert',
    user: 'be99',
    userType: '12',
    timestamp: new Date(),
  },
];

export const conversionChartMockData = [
  { daysAgo: 1, value: 15 },
  { daysAgo: 2, value: 9 },
  { daysAgo: 3, value: 17 },
  { daysAgo: 4, value: 16 },
  { daysAgo: 5, value: 9 },
  { daysAgo: 6, value: 17 },
  { daysAgo: 7, value: 16 },
  { daysAgo: 8, value: 9 },
  { daysAgo: 9, value: 22 },
  { daysAgo: 10, value: 16 },
  { daysAgo: 11, value: 14 },
  { daysAgo: 12, value: 21 },
  { daysAgo: 13, value: 13 },
  { daysAgo: 14, value: 9 },
];

export const analyticScoreMockData: AnalyticScoreData[] = [
  {
    level: 'Critical',
    type: 'Image Missing',
    description: 'This listing does not cotain a closeup image of the kitchen',
  },
  {
    level: 'Critical',
    type: 'Image Missing',
    description: 'This listing does not cotain a closeup image of the kitchen',
  },
  {
    level: 'Medium',
    type: 'Image Missing',
    description: 'This listing does not cotain a closeup image of the kitchen',
  },
  {
    level: 'Minor',
    type: 'Data Missing',
    description: 'This listing does not have a building year.',
  },
];

export const conversionFunnelChartMockData: ConversionFunnelData[] = [
  { index: 0, value: 100, label: 'View' },
  { index: 1, value: 75, label: 'Interaction' },
  { index: 2, value: 25, label: 'Conversion' },
  { index: 3, value: 0, label: '' },
];
